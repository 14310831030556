import { customerProductService, unitsMeasureService } from '@/api';
import { CustomerProductResource, FlagParams, MaterialSourceList, ProductList } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
import { NormalSelectOptions } from '@/resource/model/common';
import { customerProductNameReg } from '@/utils/validate';
import { SelectUnitMeasure, SelectMaterial } from '@/views/dialogs';

interface SaveCustomerProduct {
  companyId: number;
  productIdList: Array<number>;
  productName: string;
  price?: null | number;
  priceUnit: null | number;
  priceUnitName: string;
  weight?: number;
  remark: string;
  taxRateFlag: number;
}
@Component({
  components: { SelectMaterial, SelectUnitMeasure }
})
export default class AddCustomerProduct extends mixins(DialogMixin) {
  @Prop({ required: false, default: null }) public product!: CustomerProductResource | null;

  public productForm: SaveCustomerProduct = {
    companyId: 0,
    productIdList: [],
    price: undefined,
    priceUnit: null,
    priceUnitName: '',
    weight: undefined,
    remark: '',
    productName: '',
    taxRateFlag: 1
  };

  public resourceFormRules: { [P in keyof Partial<SaveCustomerProduct>]: Array<object> } = {
    productName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('product.inputName')));
            return;
          }
          if (!customerProductNameReg(value)) {
            callback(new Error(translation('product.inputLegalName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          customerProductService
            .checkProductName(value, this.productForm.companyId, this.product?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.productNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    productIdList: [
      {
        required: true,
        validator: (rule: any, values: Array<number>, callback: Function): void => {
          if (!values || values.length === 0) {
            callback(new Error(translation('customerProduct.selectPlatformProduct')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    price: [
      {
        required: true,
        validator: (rule: any, value: number | undefined | '', callback: Function): void => {
          if (value === undefined || value === '' || value < 0) {
            callback(new Error(translation('customerProduct.inputSellPrice')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    weight: [
      {
        required: true,
        validator: (rule: any, value: number | undefined | '', callback: Function): void => {
          if (value === undefined || value === '' || value < 0) {
            callback(new Error(translation('customerProduct.inputWeight')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };
  public unitsDialogVisible = false;
  public submitLoading = false;
  /**
   * 计价单位
   */
  public taxRateFlagOptions: NormalSelectOptions = [
    {
      label: translation('common.yes'),
      value: 1
    },
    {
      label: translation('common.no'),
      value: 0
    }
  ];

  public makeProducts: Array<any> = [];

  private operationType: 'add' | 'edit' = 'add';

  /**
   * 控制选择平台产品的dialog显隐
   */
  private productDialogVisible = false;

  private flagParams: FlagParams = {
    saleFlag: 1
  };

  public openSelectUnitsDialog(): void {
    this.unitsDialogVisible = true;
  }

  public handleSelectedUnits(row: any): void {
    this.productForm.priceUnit = row.id;
    this.productForm.priceUnitName = row.name;
  }
  public dialogOpen(): void {
    if (!this.product) {
      this.productForm.companyId = Number(this.$route.query.companyId);
      this.operationType = 'add';
      this.title = 'customerProduct.addCustomerProduct';
      this.getPriceUnits();
    } else {
      this.operationType = 'edit';
      this.title = 'customerProduct.editCustomerProduct';
      this.$nextTick(() => {
        Object.assign(this.productForm, this.product);
        this.reviewMakeProducts();
      });
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    this.makeProducts = [];
    this.resetForm();
  }

  public resetForm(): void {
    (this.$refs.productForm as Form).resetFields();
    Object.assign(this.productForm, {
      id: null,
      companyId: 0,
      productIdList: [],
      makeProductList: undefined,
      price: undefined,
      priceUnit: null,
      priceUnitName: '',
      weight: undefined,
      remark: '',
      productName: '',
      taxRateFlag: 1
    });
  }

  public onSubmit(): void {
    (this.$refs.productForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await customerProductService.post({ ...this.productForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await customerProductService.put({ ...this.productForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success');
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public selectPlatformProduct(): void {
    this.productDialogVisible = true;
  }

  public handleSelectedProduct(products: Array<MaterialSourceList>): void {
    const toAddProducts = products.filter(x => !this.makeProducts.find(y => y.id === x.id));
    this.productForm.productIdList.push(...toAddProducts.map(x => x.id));
    this.makeProducts.push(
      ...((toAddProducts?.map(x => {
        return {
          id: x.id,
          name: x.name
        } as ProductList;
      }) ?? []) as Array<any>)
    );
  }

  public removeMakeProduct(id: number): void {
    this.makeProducts = this.makeProducts.filter(x => x.id !== id);
    this.productForm.productIdList = this.productForm.productIdList.filter(x => x !== id);
  }

  /**
   * 处理制作品名回显
   */
  private reviewMakeProducts(): void {
    this.productForm.productIdList = this.product!.makeProductList?.map(x => x.id) ?? [];
    this.makeProducts =
      this.product!.makeProductList?.map(x => {
        return {
          id: x.id,
          name: x.name
        } as ProductList;
      }) ?? [];
  }
  /**
   * 计价单位默认值
   */
  private async getPriceUnits(): Promise<void> {
    const unitOptionData = await unitsMeasureService.getPriceUnits();
    unitOptionData.forEach((item: any) => {
      const id: number = item.value;
      const name: string = item.label;
      switch (item.label) {
        case '平方米':
          // 基本单位
          this.productForm.priceUnit = id;
          this.productForm.priceUnitName = name;
          break;
        default:
          break;
      }
    });
  }
}
