import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { ImportRes, CustomerProductList } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  downloadFileByBlob,
  getResourceApproveStatusOptions,
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  handleImportError,
  messageError,
  messageErrors,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { PublicApproveStatusEnum, ResourceStatusEnum, ImportTemplateEnum } from '@/resource/enum';
import { cloneDeep } from 'lodash-es';
import { ImportFile } from '@/views/dialogs/index';
import { ApiResponse } from '@/api/axios';
import { classifyService, customerProductService } from '@/api';
import AddCustomerProduct from './add-customer-product/add-customer-product.vue';
@Component({
  name: 'CustomerProduct',
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, ImportFile, AddCustomerProduct }
})
export default class CustomerProduct extends Vue {
  public tableOption: OsTableOption<CustomerProductList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerProductList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'productCode',
      label: 'product.code',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'categoryName',
      label: 'product.productClassify',
      showOverflowTooltip: true,
      minWidth: '200px',
      formatter: (row: Object): string => {
        const data: CustomerProductList = row as CustomerProductList;

        const list = [];
        if (data.categoryName) {
          list.push(data.categoryName);
        }
        if (data.secondCategoryName) {
          list.push(data.secondCategoryName);
        }

        return list.join(' / ');
      }
    },
    {
      prop: 'productName',
      label: 'customerProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'makeProductList',
      label: 'customerProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'price',
      label: 'customerProduct.productUnitPrice',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: object): string => {
        const unit = (rowData as CustomerProductList).priceUnitName;
        return `${(rowData as CustomerProductList).price}${translation('common.yuan')} / ${unit}`;
      }
    },
    {
      prop: 'taxRateFlag',
      label: 'customerProduct.taxRateFlag',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: object): string => {
        return `${
          (rowData as CustomerProductList).taxRateFlag === 1 ? translation('common.yes') : translation('common.no')
        }`;
      }
    },
    {
      prop: 'weight',
      label: 'customerProduct.weight',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'status',
      label: 'common.status',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'approveStatus',
      label: 'common.approveStatus',
      showOverflowTooltip: true,
      minWidth: '100px',
      formatter: (rowData: object): string => {
        return translation(`publicApproveStatus.${PublicApproveStatusEnum[(rowData as CustomerProductList).approveStatus]}`);
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Cascader',
      field: 'categoryId',
      label: 'product.productClassify',
      option: {
        placeholder: 'product.selectClassify',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    },
    {
      type: 'Select',
      field: 'approveStatus',
      label: 'common.approveStatus',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceApproveStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'customer:product:save',
      handleClick: (): void => {
        this.openProductDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'customer:product:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      slot: 'start',
      type: 'primary',
      label: "button.approve",
      operationType: 'approve',
      disabled: true,
      permissionCode: 'customer:product:approve',
      handleClick: (): void => {
        this.approve();
      }
    },
    {
      slot: 'start',
      type: 'danger',
      label: 'button.reverseApprove',
      operationType: 'reverseApprove',
      disabled: true,
      permissionCode: 'customer:product:reverseApprove',
      handleClick: (): void => {
        this.reverseApprove();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'customer:product:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteProduct();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'customer:product:editStatus',
      handleClick: (): void => {
        this.batchUpdateProductStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'customer:product:editStatus',
      handleClick: (): void => {
        this.batchUpdateProductStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<CustomerProductList> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'customer:product:edit',
        dynamicHidden: (rowData: CustomerProductList): boolean => {
          return rowData.approveStatus !== PublicApproveStatusEnum.waitApprove;
        },
        handleClick: (item: CustomerProductList): void => {
          this.openProductDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'customer:product:batchDelete',
        dynamicHidden: (rowData: CustomerProductList): boolean => {
          return rowData.approveStatus !== PublicApproveStatusEnum.waitApprove;
        },
        handleClick: (item: CustomerProductList): void => {
          this.deleteProduct(item);
        }
      }
    ]
  };
  /**
   * 控制导入的dialog显示隐藏
   */
  public importDialogVisible = false;
  public importTitle = 'dialog.importPlatformProduct';
  public editRow: CustomerProductList | null = null;
  public dialogVisible = false;
  public totalData = 0;
  /**
   * 导入模板的名称
   */
  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.customerProduct;

  /**
   * 选中行
   */
  private selectedRows: Array<CustomerProductList> = [];
  
  /**
   * 查询表单
   */
  private queryForm: Partial<{
    /**
     * 产品分类
     */
    categoryId: number | null;
    keywords: string;
    status: ResourceStatusEnum | null;
    companyId: number;
    approveStatus: PublicApproveStatusEnum | null;
  }> = {};

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.getProductClassify();
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.productTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<CustomerProductList>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(): void {
    this.loadData();
  }

  public importSuccess(path: string): void {
    customerProductService
      .importData(path, this.queryForm.companyId!)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  /**
   * 下载导入模板
   */
  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    customerProductService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob(translation('customerProduct.productTemplateName'), blob);
      })
      .catch((error: string) => {
        Message.error(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  public getMakeProductNames(makeProducts?: Array<{ id: number; name: string }>): string {
    return makeProducts?.map(x => x.name).join('<br/>') ?? '';
  }

  private openProductDialog(data: CustomerProductList | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CustomerProductList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }

  /**
   * 删除客户产品
   * @param data 数据行
   */
  private deleteProduct(data: CustomerProductList): void {
    // 验证所选数据是否待审核
    if(data.approveStatus !== PublicApproveStatusEnum.waitApprove) {
      Message.warning(translation('tip.noWaitApproveData'));
      return;
    }

    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await customerProductService.batchDelete([data.id]);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 批量删除客户产品
   */
  private async batchDeleteProduct(): Promise<void> {
    const waitApproveRows = this.selectedRows.filter(x => x.approveStatus === PublicApproveStatusEnum.waitApprove);

    // 验证所选数据是否待审核
    if(waitApproveRows.length === 0) {
      Message.warning(translation('tip.noWaitApproveData'));
      return;
    }

    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await customerProductService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    customerProductService
      .getList(this.queryForm as CustomerProductList, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private batchUpdateProductStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    customerProductService
      .batchUpdateProductStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getProductClassify(): void {
    classifyService
      .getTreeData()
      .then(res => {
        const option = this.queryItemsOption.find(x => x.field === 'categoryId');
        if (!option) {
          return;
        }
        option.optionData = classifyService.handleCascaderOption(res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }

  /**
   * 审核
   */
  private approve(): void {
    // 验证所选数据是否启用状态
    const enableRows = this.selectedRows.filter(x => x.status === ResourceStatusEnum.using);

    if(enableRows.length === 0) {
      Message.warning(translation('tip.noEnableData'));
      return;
    }

    const waitApproveRows = enableRows.filter(x => x.approveStatus === PublicApproveStatusEnum.waitApprove);

    // 验证所选数据是否待审核
    if(waitApproveRows.length === 0) {
      Message.warning(translation('tip.noWaitApproveData'));
      return;
    }

    customerProductService.approve(waitApproveRows.map(x => x.id)).then(() => {
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    });
  }

  /**
   * 反审核
   */
  private reverseApprove(): void {
    // 验证所选数据是否启用状态
    const enableRows = this.selectedRows.filter(x => x.status === ResourceStatusEnum.using);

    if(enableRows.length === 0) {
      Message.warning(translation('tip.noEnableData'));
      return;
    }

    const approveRows = enableRows.filter(x => x.approveStatus === PublicApproveStatusEnum.passed);

    // 数据合规验证
    if(approveRows.length === 0) {
      Message.warning(translation('tip.noApprovedData'));
      return;
    }

    customerProductService.reverseApprove(approveRows.map(x => x.id)).then(() => {
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    });
  }
}
